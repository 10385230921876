<template>
	<div class="page_box">

		
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">
					<div class="btns" @click="do_add()">
						新增
					</div>
			
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- 暂时无需 -->
					<!-- <el-table-column type="selection" width="55" label="序号">
					</el-table-column> -->
					<el-table-column prop="id" label="岗位名称" width=""></el-table-column>
					<el-table-column prop="name" label="有证书固定薪资" width=""></el-table-column>
					<el-table-column prop="mobile" label="无证书固定薪资" width=""></el-table-column>
					<el-table-column prop="created_at" label="有证书底薪" width=""></el-table-column>
					<el-table-column prop="created_at" label="无证书底薪" width=""></el-table-column>
					<el-table-column prop="created_at" label="提成" width=""></el-table-column>

				
					<!-- 操作模块   -->
					<el-table-column fixed="right" label="操作" width="140">
						<template slot-scope="scope">
							<el-button type="text" size="small" class="btn_modify"
								@click="handle_btn_modify(scope.row)">薪资设置</el-button>
						<!-- 	<el-button type="text" size="small" class="btn_delete"
								@click="handle_btn_delete(scope.row)">减员</el-button> -->
						</template>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>


		<!--  -->
		<position_xinzi_add_modal data-title="岗位薪资设置" ref="position_xinzi_add_modal" />


	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的
	import position_xinzi_add_modal from "@/components/xinzi/position_xinzi_add_modal.vue"; //岗位薪资设置

	export default {
		components: {
			page,
			position_xinzi_add_modal
		},
		data() {
			return {
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '',
					identity: '',
				},
				list: [{
						name: '123123'
					},
					{
						name: '123123'
					}, {
						name: '123123'
					}, {
						name: '123123'
					},
				],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围


				//
				month_select: '',
				date_list: [
					// {date: '2024-05-01', week: '星期日',},
				],
			};
		},

		methods: {
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				this.set_date_list();


				console.log(this.forms)
				this.loading = true
				this.$api("getEmployeeList", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
					} else {
						alertErr(res.msg)
					}
				});
			},




			set_date_list() {
				let month_value = this.month_select.substring(0, 7);
				let day_number = 30;
				let date_list = []
				for (var i = 1; i <= day_number; i++) {
					let day = i < 10 ? '0' + i : i;
					let date = `${month_value}-${day}`;
					let week_number = dayjs(date).day()
					let week_text = {
						0: '星期日',
						1: '星期一',
						2: '星期二',
						3: '星期三',
						4: '星期四',
						5: '星期五',
						6: '星期六',
					} [week_number]
					let info = {
						date: date,
						week: week_text,
					}
					date_list.push(info)
				}
				this.date_list = date_list;

				console.log('当前月份日期列表', JSON.parse(JSON.stringify(this.date_list)))
			},
			
			
			do_add() {
							this.$refs.position_xinzi_add_modal.init()
			},
			handle_btn_modify(row) {
				this.$refs.position_xinzi_add_modal.init(row)
			}
			
		},
		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			// this.get_list()
		},
		mounted() {

		}


	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						margin-left: 20px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>